import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const data = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: siteUrl
        }
      }
      defaultImage: file(relativePath: { eq: "slider1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 2120
            placeholder: TRACED_SVG
          )
        }
      }
    }
  `)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
  } = data.site.siteMetadata

  const defaultImage = getSrc(data.defaultImage)

  const SEO = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={SEO.title} titleTemplate={titleTemplate}>
      <meta name="description" content={SEO.description} />
      <meta name="image" content={SEO.image} />

      {SEO.url && <meta property="og:url" content={SEO.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {SEO.title && <meta property="og:title" content={SEO.title} />}

      {SEO.description && (
        <meta property="og:description" content={SEO.description} />
      )}

      {SEO.image && <meta property="og:image" content={SEO.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {SEO.title && <meta name="twitter:title" content={SEO.title} />}

      {SEO.description && (
        <meta name="twitter:description" content={SEO.description} />
      )}

      {SEO.image && <meta name="twitter:image" content={SEO.image} />}

      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "Service",
            "serviceType": "Limousine service",
            "provider": {
              "@type": "LocalBusiness",
              "name": "Amazing Limousines",
              "address": "3380 La Sierra Ave, Riverside, CA 92503, USA",
              "telephone": "877-615-4667",
              "priceRange": "$$$",
              "image": "https://al-dev.netlify.com/static/slider1-3ad5ed89ab4d44c8ea73d34b1ea34611-6278b.jpg"
            },
            "areaServed": {
              "@type": "State",
              "name": "California"
            },
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Limousine services",
              "itemListElement":
              [
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Anniversary limousine service"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Bachelor/Bachelorette party limousine service"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Birthday limousine service"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Concert limousine service"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Corporate limousine service"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Sightseeing/Tour limousine service"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Sport events limousine service"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Wedding limousine service"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Wine Tour limousine service"
                  }
                }
              ]
            }
          }
        `}
      </script>
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}