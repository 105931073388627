import React,{useEffect} from "react";
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";

import SectionMailchimp from "../components/SectionMailchimp.js";

import phoneIcon from "./img-phone.svg";
import twitter from "./twitter.svg";
import linkedin from "./linkedin.svg";
import instagram from "./instagram.svg";
import facebook from "./facebook.svg";
import yelp from "./yelp.svg";

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 100px 30px 100px;
  align-items: center;
  background-color: #000;
  padding-bottom: 100px;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 50px 25px 100px 25px;
  }
`;

const FooterSiteTitle = styled.p`
  margin-top: 0;
  margin-bottom: 16px;
  margin-right: 0;
  font-size: 14px;
  color: #aaa;
  text-align: center;

  a {
    text-decoration: none;
    color: #424242;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const FooterSiteFastweb = styled.p`
  margin-top: 16px;
  margin-bottom: 0px;
  margin-right: 0;
  font-size: 14px;
  color: #aaa;
  text-align: center;

  a {
    text-decoration: none;
    color: #424242;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const FooterNav = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 16px;

  @media (max-width: 1024px) {
    margin-top: 16px;
  }
`;

const MainNavLink = styled(Link)`
  text-transform: uppercase;
  margin: 6px 16px;
  font-weight: 900;
  font-size: 18px;
  color: #424242;
  text-decoration: none;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

const PhoneButton = styled.a`
  display: flex;
  align-items: center;
  padding: 10px 25px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  background-color: orange;
  margin-left: 10px;
  border-radius: 3px;

  @media (max-width: 1024px) {
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    border-radius: unset;
    z-index: 9999;
  }
`;

const Icon = styled.img`
  width: 18px;
  margin-bottom: 0;
  margin-right: 10px;
`;

const FooterNavLink = styled(MainNavLink)`
  font-size: 14px;
  font-weight: 400;
  margin: 6px 16px;
`;

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    display: block;
    text-align: center;
  }
`;

const SocialIcon = styled.img`
  width: 24px;
  margin: 0 10px;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteMainQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  useEffect(() => {
    // Function to load Chatra script
    const loadChatra = () => {
      (function(d, w, c) {
        w.ChatraID = '8Ahf88uwy2Ro5KKTp';
        var s = d.createElement('script');
        w[c] = w[c] || function() {
          (w[c].q = w[c].q || []).push(arguments);
        };
        s.async = true;
        s.src = 'https://call.chatra.io/chatra.js';
        if (d.head) d.head.appendChild(s);
      })(document, window, 'Chatra');
    };

    // Call the function to load Chatra
    loadChatra();
  }, []);
  return (
    <div className="3xl:max-w-screen-2xl mx-auto">
      <div className="flex flex-col xl:flex-row items-center justify-center xl:justify-between p-6 xl:p-4">
        <Link to="/" style={{ textDecoration: `none`, color: `#0d56a0` }}>
          <StaticImage src="../img/img-logo.png" alt={data.site.siteMetadata.title} className="z-50 mb-6 xl:-mb-32" />
        </Link>
        <div className="flex items-center justify-center flex-wrap">
          <MainNavLink to="/">Home</MainNavLink>
          <MainNavLink to="/about/">About</MainNavLink>
          <MainNavLink to="/services/">Services</MainNavLink>
          <MainNavLink to="/fleet/">Fleet</MainNavLink>
          <MainNavLink to="/testimonials/">Testimonials</MainNavLink>
          <MainNavLink to="/contact/">Contact</MainNavLink>
          <PhoneButton href="tel:8776154667">
            <Icon src={phoneIcon}/>
            <span>(877) 615-4667</span>
          </PhoneButton>
        </div>
      </div>

      {children}

      <SectionMailchimp />

      <Footer>
        <FooterNav className="flex-wrap items-center justify-center">
          <FooterNavLink to="/">Home</FooterNavLink>
          <FooterNavLink to="/about/">About</FooterNavLink>
          <FooterNavLink to="/services/">Services</FooterNavLink>
          <FooterNavLink to="/fleet/">Fleet</FooterNavLink>
          <FooterNavLink to="/testimonials/">Testimonials</FooterNavLink>
          <FooterNavLink to="/contact/">Contact</FooterNavLink>
        </FooterNav>
        <FooterBottom>
          <FooterSiteTitle>© {new Date().getFullYear()} {data.site.siteMetadata.title}<br/>■ <a href="https://goo.gl/maps/cuv31wBM6cz" target="_blank" rel="noopener noreferrer">6260 Rutland Ave Riverside, CA 92503</a></FooterSiteTitle>
          <div className="flex items-center justify-center">
            <a href="https://www.facebook.com/Amazinglimos.net/" target="_blank" rel="noopener noreferrer"><SocialIcon src={facebook} /></a>
            <a href="https://twitter.com/amazinglimos_" target="_blank" rel="noopener noreferrer"><SocialIcon src={twitter} /></a>
            <a href="https://www.instagram.com/amazing_limos/" target="_blank" rel="noopener noreferrer"><SocialIcon src={instagram} /></a>
            <a href="https://www.linkedin.com/company/15661086/" target="_blank" rel="noopener noreferrer"><SocialIcon src={linkedin} /></a>
            <a href="https://www.yelp.com/biz/amazing-limousines-riverside" target="_blank" rel="noopener noreferrer"><SocialIcon src={yelp} /></a>
          </div>
          <FooterSiteFastweb>Maintained With Love by  <a href="https://digitalwisemedia.com/about-digitalwise-media/" target="_blank" rel="noopener noreferrer">Jennifer Martin DigitalWise Media</a></FooterSiteFastweb>
        </FooterBottom>
      </Footer>
    </div>
  );
}

export default Layout