import React, { Component } from "react";
import styled from "styled-components";
import addToMailchimp from 'gatsby-plugin-mailchimp';
import SimpleReactValidator from 'simple-react-validator'

import { Section1ColBlack, Wrapper, SectionH2, SectionParag } from "../styles/style.js";

const SubmitButton = styled.button`
  display: block;
  background-color: orange;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 15px 20px;
  border-radius: 3px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
  border: none;
  cursor: pointer;
`;

class SectionMailchimp extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator()
    this.state = {
      email: '',
      status: '',
    }
  }

  handleFormSubmit = e => {
    e.preventDefault();

    if (this.validator.allValid()) {
      addToMailchimp(this.state.email)
      .then(data => {
        this.setState({
          status: data.result
        })
      })
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }

  render() {
    return (
      <Section1ColBlack>
        <Wrapper>
          <SectionH2 style={{ color: `#fff` }}>Get the latest deals and updates</SectionH2>
          <SectionParag style={{ color: `#fff` }}>
            Sign up to our amazing newsletter
          </SectionParag>
          {this.state.status === `success` ? (
            <SectionParag style={{ color: `#fff` }}>Thank you for subscribing!</SectionParag>
          ) : (
            <div>
              <form
                id="email-capture"
                method="post"
                noValidate
              >
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="you@email.com"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                    style={{ padding: `8px 15px` }}
                  />
                  {this.validator.message('email', this.state.email, 'required|email', {className: 'text-red-500 text-xs italic'})}
                  <SubmitButton 
                    type="submit" 
                    onClick={e => this.handleFormSubmit(e)}
                  >
                    Subscribe
                  </SubmitButton>

                  {this.state.status === 'success' &&
                    <div className='mt-3'>
                      <div className="text-white font-semibold">
                        Thank you for subscribing!
                      </div>
                    </div>
                  }

                  {this.state.status === 'error' &&
                    <div className='mt-3'>
                      <div className="text-white font-semibold">
                        This email has already been added.
                      </div>
                    </div>
                  }
                </div>
              </form>
            </div>
          )}
        </Wrapper>
      </Section1ColBlack>
    );
  }
}

export default SectionMailchimp;